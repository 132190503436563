import { LOGIN_SUCCESS,ADD_TOKEN,CREATE_POST_FAILURE,CREATE_POST_SUCCESS,GET_POST_LIST_SUCCESS,GET_POST_LIST_FAILURE } from "../action";

const initialState = {
  user: {},
  posts:[],
  token:  "",
  appSettings: undefined,
};
const reducer = (state = initialState, action) => {  
  switch (action.type) {    
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        posts: [...state.posts, action.payload],
      };
    case CREATE_POST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.accessToken,
        // user: action.payload.data.user,
      };
    case ADD_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case 'SETTINGS':
      return { ...state, appSettings: action.payload };
      case GET_POST_LIST_SUCCESS:
        return {
          ...state,
          posts: action.payload,
        };
      case GET_POST_LIST_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
    default:
      return state;
  }
};

export default reducer;