export const SIGNIN_INITIAL_VALUES = {
    email: "",
    password: "",
    //term_and_condition: false,
  };

  export  const CREATE_POST_INITIAL_VALUE={
    heading: '',
    content: '',
    files: [],
    pinPost: false,
  }