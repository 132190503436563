import * as Yup from "yup";
const emailValidation = Yup.string()
  .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email")
  .required("Email required");

const passwordValidation = Yup.string()
  .min(8, "Password must be at least 8 characters")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  )
  .required("Password required");
export const SIGNIN_VALIDATION_SCHEMA = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

export const CREATE_POST_SCHEMA = Yup.object().shape({
  heading: Yup.string().required("Heading is required"),
  content: Yup.string().required("Content is required"),
});
