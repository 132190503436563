import Login from "./pages/authPages/Login";
import HomeFeed from "./pages/authPages/HomeFeed";
import OnBoarding from "./pages/onBoarding/OnBoarding";
import SetupAccount from "./pages/authPages/SetupAccount";
import ForgotPassword from "./pages/authPages/ForgotPassword";

const routes = {
  onBoardingRoutes: [
    // { component: OnBoarding, path: "/" },
    { path: "/", component: Login },
  ],
  publicRoutes: [
    { path: "/", component: Login },
    { path: "/setupaccount", component: SetupAccount },
    { path: "/forgotpassword", component: ForgotPassword },
  ],
  protectedRoutes: [
    // {  path: "/",component: OnBoarding },
    { path: "/", component: Login },
    
    { path: "/homefeed", component: HomeFeed },
  ],
};

export default routes;