// CustomLoader.js
import React from 'react';

const CustomLoader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
      <h4>Loading...</h4>
    </div>
  );
};

export default CustomLoader;