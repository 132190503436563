
import { BASE_URL } from "../../utils/GlobalUtils";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ADD_TOKEN = 'ADD_TOKEN';

export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';
export const GET_POST_LIST_SUCCESS = 'GET_POST_LIST_SUCCESS';
export const GET_POST_LIST_FAILURE = 'GET_POST_LIST_FAILURE';

export const addToken = (token) => ({
  type: ADD_TOKEN,
  payload: token,
});

export const login = (credentials) => {
  return async (dispatch) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {      
      const response = await fetch(`${BASE_URL}auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      const data = await response.json();      
      const accessToken = data.access_token;
      dispatch({ type: LOGIN_SUCCESS, payload: { accessToken } });
      dispatch(addToken(accessToken));
      return data;
    } catch (error) {
      console.error("Login failed:", error.message);
      throw error;
    }
  };
};
export const createPost = (formData,token) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BASE_URL}posts`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const error = await response.json();
        dispatch({ type: CREATE_POST_FAILURE, payload: error });
        throw new Error(error.message);
      }
      const data = await response.json();      
      // dispatch({ type: CREATE_POST_SUCCESS, payload: data });
      return data;
    } catch (error) {
      console.error('Error creating post:', error);
      throw error;
    }
  };
};
export const updatePost = async(postId,data,token) => {      
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      
      const raw = JSON.stringify({
        "label": data.label,
        "content": data.content,
        "title": data.title,
        "isPinned": true
      });
      
      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      
    await  fetch(`${BASE_URL}posts/${postId}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    } catch (error) {
      console.error('Error creating post:', error);
      throw error;
    }  
}
export const getPostList = (token,category,sortBy) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BASE_URL}posts/list?label=${category}&sortOrder?=${sortBy}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });      
      if (!response.ok) {
        const error = await response.json();
        dispatch({ type: GET_POST_LIST_FAILURE, payload: error });
        throw new Error(error.message);
      }
      const data = await response.json();      
      // dispatch({ type: GET_POST_LIST_SUCCESS, payload: data });
      return data;
    } catch (error) {      
      dispatch({ type: GET_POST_LIST_FAILURE, payload: error.message });
      throw error;
    }
  };
};
export const deletePost=async (postId,token)=>{
  try{
    const response= await fetch(`${BASE_URL}posts/${postId}`,{
      method: 'DELETE',
      headers:{
        'Authorization': `Bearer ${token}`,
      }
    });
    // const data = await response.json();   
    // return data;
  }catch(error){
    console.error('Error deleting post:', error);
    throw error;
  }
}
export const appSettings=(payload)=>{
  return {type:"SETTINGS",payload:payload}
}
