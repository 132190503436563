import { useState } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import StandardButton from "../../components/buttons/StandardButton";
import NormalInput from "../../components/inputs/NormalInput";

const SetupAccount = () => {
  const [flowAt, setFlowAt] = useState(0);
  const [whereismyCodeModal, setWhereisMyCodeModal] = useState()

  const renderComponent = () => {
    switch (flowAt) {
      case 0:
        return (
          <>
            <CardHeader
              className="text-center bg-transparent border-none mb-3 "
              tag={"div"}
            >
              <h4 className="f-7 m-0 mt-3 font-32px">Set Up Account</h4>
              
            </CardHeader>
            <CardBody>
              <h6 className="text-center f-6 m-0 mt-1">Let’s Get Started</h6>
              <div className="mb-5 d-flex flex-column align-items-center">
                <Button
                  onClick={() => setFlowAt(1)}
                  className="border-none btn-blue button-width py-3 mt-3 mb-2 mx-2 f-7"
                >
                  Set Up Account
                </Button>
                <small className="d-block text-center mx-3 opacity-50 small-width ">
                  By creating an account. you agree to our
                  Terms of Service and
                  Privacy Policy
                </small>
              </div>
              <div className="d-flex flex-column align-items-center">
                <small className="text-center d-block ">
                  Already have an account?
                </small>
                <Button className="button-width mx-2 btn-grey py-3 border-05 border-grey  text-black mt-3 f-7">
                  Login to your Account
                </Button>
              </div>
            </CardBody>
          </>
        );

      case 1:
        return (
          <>
            <CardHeader
              className="text-center bg-transparent border-none mb-3 "
              tag={"div"}
            >
              <h4 className="f-6 ">Verification Code</h4>
              <small className="d-block">
                Please enter your verification code to get started.
              </small>
            </CardHeader>
            <CardBody>
              <NormalInput
                label={'Verification Code'}
              />
              <div className=" d-flex flex-column align-items-center">
                <StandardButton
                  nextCaret
                  onClick={() => setFlowAt(2)}
                  className="border-none btn-blue full-width py-3 mt-3 mb-2 mx-2"
                >
                  Next
                </StandardButton>

              </div>
            </CardBody>
          </>
        );
    }
  };

  return (
    <div
      style={{ maxWidth: "416px" }}
      className="container d-flex align-items-center justify-content-center vh-100 "
    >
      <Card style={{ borderRadius: "48px" }} className="py-3  desktop-height">
        {renderComponent()}
      </Card>
    </div>
  );
};

export default SetupAccount;
