import "./App.css";
import { store, persistor } from "./redux/store"; // Updated import to include persistor
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import AppRoutes from "./components/globals/AppRoutes";
import { useEffect } from "react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRoutesWrapper />
      </PersistGate>
    </Provider>
  );
}

function AppRoutesWrapper() {
  const token = useSelector((state) => state.token);

  useEffect(() => {    
  }, [token]);

  return <AppRoutes />;
}

export default App;