import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DropdownComponent = ({
  onClick,
  className,
  buttonLabel = "Button Dropdown",
  items = [],
  width = "83px"
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} style={{ width: width }}>
      <DropdownToggle className={`${className}`} onClick={onClick}>
        {buttonLabel}
      </DropdownToggle>
      <div
        className=""
        onClick={(event) => event.stopPropagation()}
        style={{ position: "relative" }}
      >
        {" "}
        <DropdownMenu>
          {items.length > 0 ? (
            items.map((item, index) =>
              item.divider ? (
                <DropdownItem key={index} divider />
              ) : (
                <DropdownItem
                  key={index}
                  disabled={item.disabled}
                  onClick={item.onClick}
                >
                  {item.label}
                </DropdownItem>
              )
            )
          ) : (
            <>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem disabled>Action</DropdownItem>
              <DropdownItem>Another Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Another Action</DropdownItem>
            </>
          )}
        </DropdownMenu>
      </div>
    </ButtonDropdown>
  );
};

export default DropdownComponent;
