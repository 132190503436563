import Header from "./Header";
import routes from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { appSettings } from "../../redux/action";
import AuthLayout from "../../layouts/AuthLayout";
import Splash from "../../pages/onBoarding/Splash";
import { defaultAppSettings } from "../../Defaults";
import NormalLayout from "../../layouts/NormalLayout";

const AppRoutes = () => {
  const [flowAt, setFlowAt] = useState("splash");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token); // Get token from Redux state

  useEffect(() => {    
    dispatch(appSettings(defaultAppSettings));
  }, [dispatch]);

  useEffect(() => {    
    const splashLoadTimer = setTimeout(() => {
      setFlowAt("on-boarding");
    }, 2000);
    return () => {
      clearTimeout(splashLoadTimer);
    };
  }, []);

  const renderLayout = () => {    
    if (token) {
      return (
        <NormalLayout
          routes={routes.protectedRoutes} // Use protected routes if token is present
          onFinalStep={() => setFlowAt('auth')}
        />
      );
    } else {
      return (
        <AuthLayout
          routes={routes.publicRoutes} // Use public routes if token is not present
          onFinalStep={() => setFlowAt('')}
        />
      );
    }
  };

  return (
    <>
      {flowAt === 'splash' ? (
        <Splash /> // Replace with your splash screen component
      ) : (
        renderLayout()
      )}
    </>
  );
};

export default AppRoutes;